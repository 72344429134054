import React from 'react';
import {useStaticQuery, graphql} from 'gatsby'

const Location = ({subPage}) => {
    const data = useStaticQuery(graphql`
    query {
      wbLogo: file(relativePath: { eq: "logo2018.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const {wbLogo} = data
  return (
    <div className={`flex flex-wrap text-gray-700 text-sm mb-8 md:mb-0 ${subPage ? 'mt-4' : 'pl-4'}`} itemScope="" itemType="http://schema.org/LocalBusiness">
      <div className="w-1/2 md:w-full md:mb-4">
        <img src={wbLogo.childImageSharp.fluid.src} alt="Willow Branch Flowers and Design Logo"/>
      </div>
      <div className={`w-1/2 md:w-full ${subPage ? '' : 'bg-bird'}`}>
        <span className="text-green-900 font-semibold text-xl font-serif hidden" itemProp="name">Willow Branch Flowers</span>
        <address itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress" className="not-italic">
            <span itemProp="streetAddress">618 N. Main Street</span> <br />
            <span itemProp="addressLocality">Mooresville</span>,
            <span itemProp="addressRegion"> NC</span>
            <span itemProp="postalCode"> 28115</span> <br/>
            <span itemProp="postalCode" className="text-green-900 text-lg font-semibold font-serif"><a href="tel:+17046631467">704-663-1467</a></span><br/>
            Mon-Sat: Appointment Only <br />
        </address>
      </div>
    </div>
  );
}

export default Location;
