import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { SRLWrapper } from "simple-react-lightbox"
import Location from "../components/location"

export const query = graphql`
  query BrideData($slug: String!) {
    bride: sanityBride(slug: { current: { eq: $slug } }) {
      name
      description
      bridalImages {
        asset {
          url
        }
      }
      listingImage {
        asset {
          url
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

const SingleBrideTemplate = props => {
  const { data } = props
  const bride = data && data.bride

  return (
    <Layout>
      <SEO
        title={`${bride.name} Wedding Flower Gallery`}
        description={bride.description}
      />

      <section className="bg-textured-content py-12 border-b border-gray-400">
        <div className="md:flex container mx-auto">
          <div className="md:w-1/3 pr-12 px-2 md:px-0">
            <h1 className="text-3xl font-serif font-bold text-green-800 mb-2">
              {bride.name} Wedding Gallery
            </h1>
            <p className="leading-loose mb-8 md:mb-0">{bride.description}</p>
            <p className="leading-loose mb-8 md:mb-0">
              <Link
                to="/brides"
                className="text-green-800 hover:text-green-500 font-bold"
              >
                &laquo; Back to Bridal Flower Examples
              </Link>
            </p>

            <Location subPage />
          </div>
          <div className="md:w-2/3 md:pl-4">
            <SRLWrapper>
              <div className=" flex flex-wrap">
                {bride.bridalImages.map((img, idx) => {
                  return (
                    <div
                      key={idx}
                      className={idx < 2 ? "w-1/2" : "w-1/2 md:w-1/3"}
                    >
                      <div className="bride-icon m-2 md:m-4 md:shadow-md">
                        <a
                          href={`${img.asset.url}?w=1440&h=1024&fit=crop`}
                          data-attribute="SRL"
                        >
                          <img
                            src={`${img.asset.url}?w=425&h=425&fit=crop`}
                            alt={`${bride.name} Wedding Flower Arrangement Examples`}
                          />
                        </a>
                      </div>
                    </div>
                  )
                })}
              </div>
            </SRLWrapper>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SingleBrideTemplate
